// ----------------------------------------------------------------------




const account = {
  displayName: 'Junior Oliveira',
  email: 'demo@junior.cc',
  photoURL: '/static/mock-images/perfil/1656433703054.jpg',
};

export default account;
